<template>
  <el-scrollbar style="height:100%;">
    <el-menu
      class="menu"
      :collapse="!isMenuOpen"
      background-color="#304156"
      text-color="#bfcbd9"
      default-active="1"
    >
      <menu-item
        v-for="(item, index) in routes"
        :key="item.path"
        :child="item"
        :index="~~index+1"
      ></menu-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from "vuex";
import menuItem from "./menuItem";
export default {
  computed: {
    ...mapGetters(["isMenuOpen", "routes"]),
  },

  components: {
    menuItem,
  },
};
</script>

<style scoped>
.el-menu {
  background-color: #2f3b52;
}
.el-menu:not(.el-menu--collapse) {
  width: 200px;
}
>>> .el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
>>> .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
.el-scrollbar {
  height: calc(100% + 15px);
}

>>> .el-scrollbar__view,
>>> .el-menu {
  height: 100%;
}
>>> .el-scrollbar__wrap{
    height: calc(100% + 15px);
}
</style>