import axios from "axios";
import { Loading } from "element-ui";
import Vue from "vue";
import store from "../store";
import router from "../router";
import { message } from "./newMessage.js";
let loading = null;
let logoutFlag = true;
let host = "";
const vm = new Vue({
  router,
});
host = process.env.VUE_APP_BASE_URL;
// 创建axios实例
const service = axios.create({
  baseURL: host, // api的base_url
  timeout: 100000, // 请求超时时间
});

let cancel;
const cancelToken = axios.CancelToken;
// 防止重复请求
let removePending = (config) => {
  for (let k in store.state["axiosPromiseCancel"]) {
    if (
      store.state["axiosPromiseCancel"][k].u ===
      config.url + "&" + config.method
    ) {
      //当前请求在数组中存在时执行函数体
      store.state["axiosPromiseCancel"][k].f(); //执行取消操作
      store.state["axiosPromiseCancel"].splice(k, 1); //把这条记录从数组中移除
    }
  }
};
// request拦截器
service.interceptors.request.use(
  (config) => {
    removePending(config); //在一个ajax发送前执行一下取消操作
    config.cancelToken = new cancelToken((cancel) => {
      store.state["axiosPromiseCancel"].push({
        u: config.url + "&" + config.method,
        f: cancel,
      });
    });
    loading = Loading.service({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0)",
    });
    let token = sessionStorage.getItem("token");
    config.headers["Authorization"] = token;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    removePending(response.config);
    loading.close();
    if (response.data.code == 1234) {
      vm.$route.path != "/login" && vm.$router.replace("/login");
      message.error("登录状态已失效，请重新登录");
      logoutFlag = false;
      sessionStorage.setItem("token", "");
      return Promise.reject(response);
    } else if (response.data.code == -1) {
      message.error(response.data.msg);
      return Promise.reject(response);
    } else {
      logoutFlag = true;
      return response;
    }
  },
  (error) => {
    logoutFlag = true;
    loading.close();
    return Promise.reject(error.response);
  }
);

export default service;
