<template>
  <el-container class="full-container">
    <section>
      <!-- <div class="logo" v-show="isMenuOpen">中建国际疫情管理台</div> -->
      <Menu></Menu>
    </section>
    <!-- <el-aside>
      
      <Menu></Menu>
    </el-aside> -->
    <el-container>
      <el-header
        class="header"
        style="text-align: right; font-size: 14px; height: 50px"
      >
        <div>
          <i
            class="fold-icon"
            :class="isMenuOpen ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
            @click="triggetMenu"
          ></i>
          <span class="logo">中建国际疫情管理台</span>
        </div>
        <div>
          <!-- <el-button type="primary"  size="small" style="margin-right:10px" @click="refresh">刷新</el-button> -->
          <i class="el-icon-setting" style="margin-right: 5px; color: #fff"></i>
          <p class="username">
            <span>{{ name }}</span
            ><span @click="$router.push('/reset')" v-if="name == '超级管理员'"
              >| 修改密码</span
            >
            | <span @click="logout">退出</span>
          </p>
        </div>
      </el-header>
      <el-main>
        <div class="nav">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <transition-group name="breadcrumb">
              <el-breadcrumb-item
                v-for="(name, index) in matchedArr"
                :key="name"
                >{{ name }}</el-breadcrumb-item
              >
            </transition-group>
          </el-breadcrumb>
        </div>
        <transition name="fade-transform" mode="out-in">
          <router-view />
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { getUserInfo, Vertify, refresh } from "@/api/api";
import { mapGetters } from "vuex";
import Menu from "./menu";
export default {
  data() {
    return {
      role: this.$store.state.role,
    };
  },
  components: {
    Menu,
  },
  mounted() {},
  watch: {
    name() {
      return this.$store.state.name;
    },
  },
  computed: {
    matchedArr() {
      let temp = [],
        temps = [];
      this.$route.matched.filter((item, index, self) => {
        if (item.meta.title) {
          const name = item.meta.title;
          temp.push(name);
        }
      });
      temp.filter((item, index, self) => {
        if (!temps.includes(item)) {
          temps.push(item);
        }
      });
      return temps;
    },
    name() {
      return this.$store.state.name;
    },
    ...mapGetters(["isMenuOpen"]),
  },
  methods: {
    logout() {
      sessionStorage.setItem("token", "");
      sessionStorage.setItem("name", "");
      this.$router.replace("/login");
    },
    triggetMenu() {
      this.$store.commit("setMenuOpen");
    },
    refresh() {
      refresh().then(() => {
        this.$router.go(0);
      });
    },
  },
};
</script>

<style>
.full-container {
  height: 100vh;
  overflow: hidden;
}
.el-aside {
  overflow-x: hidden;
  margin-right: -1px;
}
.el-aside::-webkit-scrollbar {
  display: none;
}
.header {
  background: url(../assets/header.png);
  background-size: 120% 120%;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header > div {
  display: flex;
  align-items: center;
}
.username {
  color: #fff;
}

.username span {
  margin: 0 10px;
  cursor: pointer;
}
.username span:first-child {
  margin-left: 0;
}
.nav {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.logo {
  color: #fff;
  font-size: 16px;
  margin-left: 15px;
  font-weight: bold;
  /* background: #242e42;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  height: 55px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  font-size: 20px;
  width: 250px; */
}
.el-breadcrumb {
  font-size: 16px;
}
.fold-icon {
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all 0.7s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.breadcrumb-move {
  transition: all 0.7s;
}

.breadcrumb-leave-active {
  position: absolute;
}
</style>