import Layout from "@/layout/layout.vue";

const management = {
  path: "/management",
  name: "Management",
  meta: {
    title: "员工管理",
    icon: "el-icon-user-solid",
  },
  component: Layout,
  children: [
    {
      path: "/management/manage",
      name: "PersonManage",
      component: () => import("@/views/person/manage.vue"),
      meta: {
        title: "全部员工",
      },
    },
    {
      // 隔离人员
      path: "/management/quarantine",
      name: "Quarantine",
      component: () => import("@/views/person/quarantine.vue"),
      meta: {
        title: "隔离人员",
      },
    },
    {
      // 人员位置
      path: "/management/position",
      name: "Position",
      component: () => import("@/views/person/position.vue"),
      meta: {
        title: "人员行程",
      },
    },
    {
      // 体温检测
      path: "/management/temperature",
      name: "Temperature",
      component: () => import("@/views/person/temperature.vue"),
      meta: {
        title: "体温检测",
      },
    },
    {
      // 防疫信息
      path: "/management/epidemic",
      name: "Epidemic",
      component: () => import("@/views/person/epidemic.vue"),
      meta: {
        title: "防疫信息",
      },
    },
  ],
};

export default management;
