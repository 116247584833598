import fetch from "../utils/fetch";

export function Vertify() {
  return fetch({
    url: "/accessToken/verify",
    method: "GET",
  });
}
//获取用户信息
export function getUserInfo(query) {
  return fetch({
    url: "/user/login",
    method: "post",
    data: query,
  });
}

// 回国休假
export function homeList(query) {
  return fetch({
    url: "/returnInfo/page",
    method: "GET",
    params: query,
  });
}

// 人员管理
export function userList(query) {
  return fetch({
    url: "/importUser/page",
    method: "GET",
    params: query,
  });
}

export function syncUser() {
  return fetch({
    url: "/user/allUserSync",
    method: "POST",
  });
}

export function isolateList(query) {
  return fetch({
    url: "/importUser/page/isolate",
    method: "GET",
    params: query,
  });
}

export function tripList(query) {
  return fetch({
    url: "/importUser/page/tripWithLevel",
    method: "GET",
    params: query,
  });
}

export function tempList(query) {
  return fetch({
    url: "/importUser/page/temperature",
    method: "GET",
    params: query,
  });
}

export function epidemicList(query) {
  return fetch({
    url: "/importUser/page/epidemicPrevent",
    method: "GET",
    params: query,
  });
}

export function epidemicTypes(query) {
  return fetch({
    url: "/vaccinesType/list",
    method: "GET",
    params: query,
  });
}

export function tempDetail(query) {
  return fetch({
    url: "/temperature/list",
    method: "GET",
    params: query,
  });
}

export function tripDetail(query) {
  return fetch({
    url: "/trip/list",
    method: "GET",
    params: query,
  });
}

export function isolateDetail(query) {
  return fetch({
    url: "/isolate/list",
    method: "GET",
    params: query,
  });
}

export function superLogin(params) {
  return fetch({
    url: "/user/loginByAdmin",
    method: "POST",
    data: params,
  });
}

// 核酸检测记录
export function nucleicList(query) {
  return fetch({
    url: "/nucleicAcid/list",
    method: "GET",
    params: query,
  });
}

// 接种疫苗记录
export function vaccinesList(query) {
  return fetch({
    url: "/vaccines/list",
    method: "GET",
    params: query,
  });
}

// 导出excel
export function excelExport(query) {
  return fetch({
    url: "/excel/exportTrip",
    method: "GET",
    params: query,
    responseType: "arraybuffer",
  });
}

// 疫区信息
export function areaList(query) {
  return fetch({
    url: "/riskLevel/page",
    method: "GET",
    params: query,
  });
}

// 设置管理员
export function setAdmin(id,query) {
  return fetch({
    url: `/importUser/${id}/update`,  
    method: "PUT",
    data: query,
  });
}
// 批量设置管理员
export function setAdminBatch(query) {
  return fetch({
    url: `/importUser/all/update`,
    method: "PUT",
    data: query,
  });
}
export function deleteAdmin(id) {
  return fetch({
    url: `/importUser/${id}/del`,
    method: "DELETE",
  });
}
// 新增用户
export function addAdmin(params) {
  return fetch({
    url: `/importUser/save`,
    method: "POST",
    data:params
  });
}


export function refresh(){
  return fetch({
    url:'/user/userPowerSync',
    method:'GET'
  })
}


export function resetPassword(data){
  let id = sessionStorage.getItem('id')
  return fetch({
    url:`/sysAdmin/${id}/update`,
    method:'PUT',
    data:data
  })
}