<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { getUserInfo, Vertify } from "@/api/api";
export default {
  mounted() {
    //this.init();
  },
  methods: {
    
  },
};
</script>
<style >
.tabler-header {
  background: #409eff;
}
.el-table thead tr {
  background-color: #409eff !important;
}
.main-content {
  margin-top: 20px;
}
.query-form {
  margin-top: 20px;
  padding: 25px 20px 0;
  background-color: #eef5fe !important;
}
.el-cascader__tags{
  max-height: 100px;
  overflow-y: auto;
}
</style>
