import Vue from "vue";
import Vuex from "vuex";
import routes from "./modules/routes";
import { getUserInfo, Vertify, refresh } from "@/api/api";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    name: sessionStorage.getItem("name"),
    uploadUrl: process.env.VUE_APP_BASE_URL,
    role: sessionStorage.getItem("role"),
    isMenuOpen: true,
    axiosPromiseCancel: [], 
  },
  mutations: {
    setName(state, data) {
      state.name = data;
    },
    setRole(state, data) {
      state.role = data;
    },
    setMenuOpen(state) {
      state.isMenuOpen = !state.isMenuOpen;
    },
  },
  actions: {
    refresh() {
      refresh().then(() => {
        setTimeout(()=>{
          window.location.reload()
        },1000)
      });
    },
  },
  modules: {
    routes: routes,
  },
  getters: {
    isMenuOpen: (state) => state.isMenuOpen,
    routes: (state) => state.routes.routes,
  },
});
