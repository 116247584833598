import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';

import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VueQuillEditor)
Vue.use(ElementUI); 
router.beforeEach((to,from,next)=>{
  console.log(from)
  document.title = to.meta.title
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
