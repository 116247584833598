<template>
  <div>
    <template v-if="!hasOneChild">
      <el-submenu :index="setIndex()">
        <template slot="title">
          <i :class="child.meta&&child.meta.icon"></i>
          <span slot="title">{{ child.meta&&child.meta.title }}</span>
        </template>
        <el-menu-item
          v-for="(item, idx) in child.children"
          :index="setIndex(idx)"
          :key="item.path"
          @click="link(item.path)"
          >{{ item.meta.title }}</el-menu-item
        >
      </el-submenu>
    </template>
    <template v-else>
      <el-menu-item :index="setIndex()" @click="link(child.path)">
        <i :class="child.meta&&child.meta.icon"></i>
        <span slot="title">{{ child.meta&&child.meta.title }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    child: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    hasOneChild() {
        let filterArray = this.child.children.filter(item=>!item.hidden)
      return !(filterArray.length > 1);
    },
  },
  methods: {
    setIndex(idx) {
      return idx ? `${this.index}-${idx}` : `${this.index}`;
    },
    link(path) {
      this.$route.path != path && this.$router.push(path);
    },
  },
};
</script>

<style scoped>

</style>