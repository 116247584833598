import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout/layout.vue";
import management from "./routes/management";
Vue.use(VueRouter);

export const routes = [
    // 登录
    {
        path: "/login",
        name: "Login",
        component: () =>
            import ("../views/login.vue"),
        hidden: true,
        meta: {
            title: "登录",
        },
    },

    // 状态查询
    {
        path: "/",
        redirect: "/personSearch",
        name: "PersonSearch",
        component: Layout,
        children: [{
            path: "/personSearch",
            name: "StatusSearch",
            component: () =>
                import ("../views/person/status.vue"),
            meta: {
                title: "员工查询",
            },
        }, ],
        meta: {
            title: "员工查询",
            icon: "el-icon-search",
        },
    },

    management,
    /******************  信息展示    ************** */
    {
        path: "/epidemicArea",
        name: "Info",
        component: Layout,
        children: [{
            path: "/epidemicArea",
            name: "EpidemicArea",
            component: () =>
                import ("../views/infomation/epidemicArea.vue"),
            meta: {
                title: "风险区域",
                icon: "",
            },
        }, ],
        meta: {
            title: "风险区域",
            icon: "el-icon-warning",
        },
    },
    {
        path: "/epidemicKnowledge",
        name: "Knowledge",
        component: Layout,
        children: [{
                path: "/epidemicKnowledge",
                name: "EpidemicKnowledge",
                component: () =>
                    import ("../views/infomation/epidemicKnowledge.vue"),
                meta: {
                    title: "防疫知识",
                },
            },
            {
                path: "/epidemicKnowledgeEdit",
                name: "epidemicKnowledgeEdit",
                hidden: true,
                component: () =>
                    import ("../views/infomation/EpidemicKnowledgeEdit.vue"),
                meta: {
                    title: "添加",
                },
            },
            {
                path: "/epidemicKnowledgeEdit/:id",
                name: "epidemicKnowledgeUpdate",
                hidden: true,
                component: () =>
                    import ("../views/infomation/EpidemicKnowledgeEdit.vue"),
                meta: {
                    title: "修改",
                },
            },
        ],
        meta: {
            title: "防疫知识",
            icon: "el-icon-info",
        },
    },
    // 组织架构
    {
        path: "/organization",
        name: "Main",
        component: Layout,
        children: [{
                path: "/organization",
                name: "Organization",
                component: () =>
                    import ("../views/organization/organization.vue"),
                meta: {
                    title: "组织架构",
                    icon: "",
                },
            },
            {
                path: "/vaccine",
                name: "Vaccine",
                component: () =>
                    import ("../views/system/vaccine.vue"),
                meta: {
                    title: "疫苗类型",
                    icon: "",
                },
            },
        ],
        meta: {
            title: "系统配置",
            icon: "el-icon-setting",
        },
    },
    {
        path: "/reset",
        name: "Reset",
        component: Layout,
        hidden: true,
        children: [{
            component: () =>
                import ("../views/reset.vue"),
            meta: {
                title: "修改密码",
            },

            path: "/reset",
        }, ],
    },
    // 主页
    // {
    //   path: "/",
    //   name: "Main",
    //   component: () => import("../layout/layout"),
    //   redirect: "/person/manage",
    //   children: [
    //     /*****************   人员管理    ****************/
    //     {
    //       path: "/person",
    //       name: "Person",
    //       meta: {
    //         // title:'人员管理'
    //       },
    //       component: () => import("../layout/router.vue"),
    //       children: [
    //         // 人员管理
    //         {
    //           path: "/person/manage",
    //           name: "PersonManage",
    //           component: () => import("../views/person/manage.vue"),
    //           meta: {
    //             title: "人员管理",
    //           },
    //         },
    //         {
    //           // 回国休假人员
    //           path: "/person/vacation",
    //           name: "PersonVacation",
    //           component: () => import("../views/person/vacationPerson.vue"),
    //           meta: {
    //             title: "回国休假人员",
    //           },
    //         },
    //         {
    //           // 隔离人员
    //           path: "/person/quarantine",
    //           name: "Quarantine",
    //           component: () => import("../views/person/quarantine.vue"),
    //           meta: {
    //             title: "隔离人员",
    //           },
    //         },
    //         {
    //           // 人员位置
    //           path: "/person/position",
    //           name: "Position",
    //           component: () => import("../views/person/position.vue"),
    //           meta: {
    //             title: "人员位置",
    //           },
    //         },
    //         {
    //           // 体温检测
    //           path: "/person/temperature",
    //           name: "Temperature",
    //           component: () => import("../views/person/temperature.vue"),
    //           meta: {
    //             title: "体温检测",
    //           },
    //         },
    //         {
    //           // 防疫信息
    //           path: "/person/epidemic",
    //           name: "Epidemic",
    //           component: () => import("../views/person/epidemic.vue"),
    //           meta: {
    //             title: "防疫信息",
    //           },
    //         },
    //       ],
    //     },

    //   ],
    // },
];

const router = new VueRouter({
    routes,
    scrollBehavior: () => ({ y: 0 }),
});

export default router;