import {routes} from "@/router"
const state = {
    routes:routes.filter(item=>!item.hidden)
}

const mutations={}

export default {
    state,
    mutations,    
}